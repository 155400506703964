import { Puzzle } from "./PuzzleDefinitions";

export type Coordinate = {
  x: number;
  y: number;
};

function PuzzleLine({
  from,
  to,
  parent,
  isGreen,
}: {
  from: Coordinate;
  to: Coordinate;
  parent: Coordinate;
  isGreen: boolean;
}) {
  return (
    <line
      x1={from.x - parent.x}
      y1={from.y - parent.y}
      x2={to.x - parent.x}
      y2={to.y - parent.y}
      style={{
        stroke: isGreen ? "#34b434" : "red",
        strokeWidth: 5,
        position: "absolute",
      }}
    />
  );
}

export function GraphLines({
  puzzleCoordinates,
  parentCoordinate,
  puzzles,
}: {
  puzzleCoordinates: Map<string, Coordinate>;
  parentCoordinate: Coordinate;
  puzzles: Puzzle[];
}) {
  return (
    <svg
      style={{
        zIndex: "1",
        position: "absolute",
        width: "100%",
        height: "100%",
      }}
      id="graph"
    >
      {puzzles.map((p) => {
        return p.dependants.map((q) => {
          const to = puzzleCoordinates.get(p.id);
          const from = puzzleCoordinates.get(q);
          if (from && to) {
            return (
              <PuzzleLine
                from={from}
                to={to}
                parent={parentCoordinate}
                isGreen={puzzles.find((r) => r.id === q)?.solved ?? false}
              />
            );
          } else {
            return undefined;
          }
        });
      })}
    </svg>
  );
}
