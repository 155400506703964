import { Box } from "@mui/material";
import { useEffect, useState } from "react";

export function LanguageImages() {
  const [imageIndex, setImageIndex] = useState(0);

  const images = [
    <img
      className="blink-image"
      alt="cat"
      src={process.env.PUBLIC_URL + "/cat.jpg"}
      width="200px"
      height="200px"
    />,
    <img
      className="blink-image"
      alt="sex"
      src={process.env.PUBLIC_URL + "/sex.webp"}
      width="200px"
      height="200px"
    />,
    <img
      className="blink-image"
      alt="go"
      src={process.env.PUBLIC_URL + "/go.jpg"}
      width="200px"
      height="200px"
    />,
    <img
      className="blink-image"
      alt="uno"
      src={process.env.PUBLIC_URL + "/uno.jpg"}
      width="200px"
      height="200px"
    />,
    null,
  ];

  useEffect(() => {
    const task = setInterval(() => {
      setImageIndex((imageIndex + 1) % 5);
    }, 5000);

    return () => clearInterval(task);
  });

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
        border: "2px solid #34b434",
      }}
    >
      {images[imageIndex] ?? <Box width="200px" height="200px" />}
    </Box>
  );
}
