import { Puzzle } from "./PuzzleDefinitions";
import { Box } from "@mui/material";
import { Link } from "@mui/icons-material";
import { NumberCombination } from "./NumberCombination";
import { MutableRefObject, useRef } from "react";

function FileLink({ link }: { link: string }) {
  return (
    <Box
      sx={{
        flexDirection: "row",
        display: "flex",
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: "25px",
      }}
    >
      <h3>Open File</h3>
      <a href={`./${link}`} target="_blank" rel="noopener noreferrer">
        <Link
          sx={{
            border: "1px solid #34b434",
            display: "flex",
            mx: "10px",
          }}
        />
      </a>
    </Box>
  );
}

function AnswerInput({
  answer,
  type,
  value,
}: {
  answer: String;
  type: string;
  value: MutableRefObject<String | null>;
}) {
  if (type === "combo") {
    return <NumberCombination length={answer.length} value={value} />;
  }
  if (type === "string") {
    return (
      <Box
        sx={{
          flexDirection: "column",
          display: "inline-flex",
          justifyContent: "center",
          justifyItems: "center",
          textJustify: "center",
          alignContent: "center",
          alignItems: "center",
          paddingX: "25px",
          paddingY: "25px",
        }}
      >
        <input
          id="answer-input"
          className="flicker"
          style={{
            fontSize: "24px",
            textTransform: "uppercase",
          }}
          minLength={answer.length}
          maxLength={answer.length}
          width={`${answer.length}px`}
          size={answer.length}
          placeholder={".".repeat(answer.length)}
          onInput={(e) => {
            value.current = (e.target as HTMLInputElement).value;
          }}
        />
      </Box>
    );
  }
}

export function PuzzleDisplay({
  puzzle,
  solve,
}: {
  puzzle: Puzzle;
  solve: (id: string) => void;
}) {
  const value = useRef<String | null>(null);
  const isFinal = puzzle.inputType === "none";

  return (
    <Box
      sx={{
        position: "relative",
        z: 0,
        background: "black",
        border: "2px solid #34b434",
        flexDirection: "column",
        display: "flex",
        justifyContent: "center",
        justifyItems: "center",
        textJustify: "center",
        alignContent: "center",
        paddingX: "25px",
        paddingTop: "25px",
        maxWidth: "80%",
      }}
    >
      {puzzle.clue}
      {puzzle.link && <FileLink link={puzzle.link} />}
      {puzzle.solved ? undefined : (
        <AnswerInput
          answer={puzzle.answer}
          type={puzzle.inputType}
          value={value}
        />
      )}
      {!isFinal && (
        <Box
          sx={{
            paddingY: "25px",
            display: "flex",
            justifyContent: "center",
            justifyItems: "center",
            textJustify: "center",
            alignContent: "center",
          }}
        >
          {puzzle.solved ? (
            <h1>UNLOCKED</h1>
          ) : (
            <button
              id="submit-btn"
              onClick={(e) => {
                if (
                  value.current?.toLocaleLowerCase() ===
                  puzzle.answer.toLocaleLowerCase()
                ) {
                  solve(puzzle.id);
                } else {
                  const btn = e.target as HTMLButtonElement;
                  btn.className = "flicker wrong";
                  btn.textContent = "WRONG!";
                  setTimeout(() => {
                    btn.className = "flicker";
                    btn.textContent = "Submit";
                  }, 1000);
                }
              }}
              className="flicker"
            >
              Submit
            </button>
          )}
        </Box>
      )}
    </Box>
  );
}
