import { Box } from "@mui/material";
import { useState } from "react";

export function Password({
  label,
  answer,
  breakdown,
}: {
  label: string;
  answer: string;
  breakdown: string[];
}) {
  const [answerPt1, answerPt2, answerPt3] = breakdown;
  const [solved, setSolved] = useState(false);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        px: "3%",
        justifyContent: "space-between",
        width: "50%",
      }}
    >
      <h1>{label}:</h1>
      {solved ? (
        <Box
          sx={{
            border: "3px solid #34b434",
            background: "black",
            fontSize: "24px",
            paddingY: "10px",
            paddingX: "0.5%",
          }}
        >
          {answerPt1}
          <span style={{ color: "red" }}>{answerPt2}</span>
          {answerPt3}
        </Box>
      ) : (
        <input
          id="answer-input"
          className="flicker"
          style={{
            fontSize: "24px",
            textTransform: "uppercase",
          }}
          minLength={answer.length}
          maxLength={answer.length}
          size={answer.length}
          placeholder={".".repeat(answer.length)}
          onInput={(e) => {
            const input = e.target as HTMLInputElement;
            if (input.value === answer) {
              input.disabled = true;
              setTimeout(() => setSolved(true), 1000);
            }
          }}
        />
      )}
    </Box>
  );
}

export function Passwords() {
  return (
    <Box
      sx={{
        display: "flex",
        minWidth: "100vw",
        minHeight: "100vh",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Password
        label="Newspaper"
        answer="broadsheet"
        breakdown={["BROAD", "5", "HEET"]}
      />
      <Password
        label="Recording Comment"
        answer="trombone"
        breakdown={["TROM", "8", "ONE"]}
      />
      <Password
        label="Lights"
        answer="lightbulb"
        breakdown={["L", "1", "GHTBULB"]}
      />
      <Password
        label="Password"
        answer="chaplain"
        breakdown={["CH", "4", "PLAIN"]}
      />
    </Box>
  );
}
