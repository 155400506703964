import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { Warning } from "./Warning";
import { MainPage } from "./main/MainPage";
import { Lights } from "./puzzles/Lights";
import { Passwords } from "./puzzles/Passwords";
import { QWERTY } from "./puzzles/QWERTY";
import { LocationPuzzle } from "./puzzles/LocationPuzzle";

function App() {
  return (
    <BrowserRouter {...{ basename: process.env.PUBLIC_URL!! }}>
      <Routes>
        <Route path="/" element={<Warning />} />
        <Route path="main-graph" element={<MainPage />} />
        <Route path="lights" element={<Lights />} />
        <Route path="password-CHAPLAIN" element={<Passwords />} />
        <Route path="letters" element={<QWERTY />} />
        <Route path="final" element={<LocationPuzzle />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
