import { ReactNode } from "react";
import { Box } from "@mui/material";
import { QWERTY } from "../puzzles/QWERTY";
import { LanguageImages } from "../puzzles/LanguageImages";

export type Puzzle = {
  index: number;
  id: string;
  link?: string;
  answer: string;
  inputType: string;
  clue?: ReactNode;
  dependants: string[];
  solved: boolean;
};

const puzzles = [
  {
    id: "go",
    index: 1,
    answer: "4317",
    inputType: "combo",
    dependants: [],
    clue: (
      <Box sx={{ display: "flex", justifyContent: "center", paddingY: "10px" }}>
        Good luck cister.
      </Box>
    ),
  }, // set up 4317 on the goban
  {
    id: "chess",
    index: 2,
    answer: "isotopes",
    inputType: "string",
    dependants: [],
    clue: (
      <Box sx={{ display: "flex", justifyContent: "center", paddingY: "10px" }}>
        Your Move.
      </Box>
    ),
  }, // set up chess puzzle in docs, hide mini crossword underneath.
  {
    id: "poker",
    index: 7,
    answer: "56789",
    inputType: "combo",
    dependants: [],
    clue: (
      <Box sx={{ display: "flex", justifyContent: "center", paddingY: "10px" }}>
        We have the winning hand.
      </Box>
    ),
  }, // set up poker hand where 5-9 straight wins. Hide middle 3 cards around the house
  {
    id: "keyboard",
    index: 3,
    answer: "faffage",
    inputType: "string",
    clue: (
      <Box sx={{ display: "flex", justifyContent: "center", paddingY: "10px" }}>
        Pitch Perfect.
      </Box>
    ),
    link: "recording.mp3",
    dependants: ["chess", "go"],
  },
  {
    id: "teapot",
    index: 4,
    answer: "947759", // diagonal of first kakuro in pdf
    inputType: "combo",
    link: "face.png",
    dependants: ["chess"],
    clue: (
      <Box sx={{ display: "flex", justifyContent: "center", paddingY: "10px" }}>
        Remember me?
      </Box>
    ),
  }, // print of kakuro and hide it in squash teapot
  {
    id: "qwerty",
    index: 7,
    answer: ",lp-['",
    inputType: "string",
    dependants: ["poker", "chess"],
    clue: <QWERTY />,
  },
  {
    id: "hidden-numbers",
    index: 9,
    answer: "2198",
    inputType: "combo",
    dependants: ["poker"],
    clue: (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          paddingY: "10px",
          flexDirection: "column",
        }}
      >
        <Box>NETWORK</Box>
        <Box>HONEY</Box>
        <Box>CANINE</Box>
        <Box>WEIGHTY</Box>
      </Box>
    ),
  },
  {
    id: "language",
    index: 8,
    answer: "4651",
    inputType: "combo",
    dependants: ["qwerty", "hidden-numbers"],
    clue: <LanguageImages />,
  },
  {
    id: "book",
    index: 13,
    answer: "lockbox",
    inputType: "string",
    dependants: ["hidden-numbers"],
    clue: (
      <Box sx={{ display: "flex", justifyContent: "center", paddingY: "10px" }}>
        Got any new books lately?
      </Box>
    ),
  }, // Put the book lockbox on the bookshelf
  {
    id: "morse",
    index: 5,
    answer: "armadillo",
    inputType: "string",
    link: "lights",
    dependants: ["keyboard", "teapot"],
    clue: (
      <Box sx={{ display: "flex", justifyContent: "center", paddingY: "10px" }}>
        Lights out.
      </Box>
    ),
  }, // Double check fuck-with-my-lights is running with a high tick rate
  {
    id: "password",
    index: 6,
    answer: "5814",
    inputType: "combo",
    clue: (
      <Box sx={{ display: "flex", justifyContent: "center", paddingY: "10px" }}>
        You'll never find them all.
      </Box>
    ),
    link: "password-CHAPLAIN",
    dependants: ["morse", "language"],
  },
  {
    id: "flatmap", // key in the bedside table, unlocks cupboard (5388) with 3d printed puzzle
    index: 10,
    answer: "49675070a", //TODO
    inputType: "combo",
    clue: (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          paddingY: "10px",
          textAlign: "center",
        }}
      >
        Line from B to D <br />
        Line from A to E <br />
        Line from X to C <br />
        END OF THE LINE.
      </Box>
    ),
    dependants: ["language"],
  }, // 3D printed puzzle under the sink, key for the sink in the couchside table
  {
    id: "photohunt",
    index: 11,
    answer: "encyclopedia",
    inputType: "string",
    link: "pichunt.jpg",
    dependants: ["book"],
    clue: (
      <Box sx={{ display: "flex", justifyContent: "center", paddingY: "10px" }}>
        Looking for this?
      </Box>
    ),
  }, // print off 3 photos hide them in order, lock key to blue box in cupboard (5388),
  // write encyclopedia anagram circle on the lid of blue box
  {
    id: "final",
    index: 12,
    answer: "",
    inputType: "none",
    clue: (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          paddingY: "10px",
          textAlign: "center",
        }}
      >
        <h1>When?</h1>
      </Box>
    ),
    link: "final",
    dependants: ["flatmap", "password", "photohunt"],
  },
];

export const puzzlesWithProgress = (): Puzzle[] => {
  return puzzles.map((puzz) => {
    return {
      ...puzz,
      solved: !!localStorage.getItem(`geofiends-${puzz.id}-solved`),
    };
  });
};

export const solvePuzzles = (
  puzzles: Puzzle[],
  setPuzzles: React.Dispatch<React.SetStateAction<Puzzle[]>>
) => {
  return (id: string) => {
    setPuzzles([
      ...puzzles.filter((q) => q.id !== id),
      {
        ...puzzles.find((p) => p.id === id)!!,
        solved: true,
      },
    ]);
  };
};
