import { Box } from "@mui/material";
import { ArrowDropUp, ArrowDropDown } from "@mui/icons-material";
import { MutableRefObject, useEffect, useRef, useState } from "react";

function Number({
  numRef,
  stringRef,
  index,
}: {
  numRef: MutableRefObject<number[]>;
  stringRef: MutableRefObject<String | null>;
  index: number;
}) {
  const [num, setNum] = useState(0);

  useEffect(() => {
    numRef.current = [
      ...numRef.current.slice(0, index),
      num,
      ...numRef.current.slice(index + 1, numRef.current.length),
    ];
    stringRef.current = numRef.current.join("");
  }, [num, numRef, stringRef, index]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyItems: "center",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        textJustify: "center",
        flexDirection: "column",
        padding: "3px",
      }}
    >
      <ArrowDropUp
        onClick={() => {
          setNum((num + 1) % 10);
        }}
      />
      <input
        type="number"
        className="flicker"
        style={{
          maxWidth: "1ch",
          minWidth: "1ch",
          border: "1px solid #34b434",
          fontSize: "36px",
        }}
        value={num}
      />
      <ArrowDropDown
        onClick={() => {
          setNum((num + 9) % 10);
        }}
      />
    </Box>
  );
}

export function NumberCombination({
  length,
  value,
}: {
  length: number;
  value: MutableRefObject<String | null>;
}) {
  const refArray = useRef(Array.from({ length }, () => 0));

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyItems: "center",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
      }}
    >
      {Array.from({ length }, (x, i) => i).map((_, index) => (
        <Number numRef={refArray} stringRef={value} index={index} />
      ))}
    </Box>
  );
}
