import { Box } from "@mui/material";
import { useEffect, useState } from "react";

const clue = "`aw3edc vgy7ujm ";
const answer = ",lp-['";
var counter = 0;

export function QWERTY() {
  const [clueText, setClueText] = useState("");

  useEffect(() => {
    const task = setInterval(() => {
      setClueText(clue.slice(0, counter % clue.length));
      counter++;
    }, 1000);

    return () => clearInterval(task);
  });

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
        height: "96px",
        fontSize: "24px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "left",
          flexDirection: "row",
          width: `${clue.length}ch`,
          height: "25%",
        }}
      >
        {clueText}
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "row",
          width: `${answer.length}ch`,
          border: "2px solid red",
          height: "29%",
        }}
      ></Box>
    </Box>
  );
}
