import React, { useEffect, useState } from "react";
import MatrixRain from "./MatrixRain";
import { Box, Stack, Grid } from "@mui/material";
import { PuzzleGraph } from "./PuzzleGraph";
import { Puzzle, puzzlesWithProgress, solvePuzzles } from "./PuzzleDefinitions";
import { PuzzleDisplay } from "./PuzzleDisplay";

export function MainPage() {
  const [puzzles, setPuzzles] = useState<Puzzle[]>(puzzlesWithProgress());
  const [currentPuzzleId, setCurrentPuzzleId] = useState<string | undefined>();
  const currentPuzzle = puzzles.find((p) => p.id === currentPuzzleId);

  useEffect(() => {
    puzzles.forEach((p) => {
      if (p.solved) {
        localStorage.setItem(`geofiends-${p.id}-solved`, "true");
      } else {
        localStorage.removeItem(`geofiends-${p.id}-solved`);
      }
    });
  }, [puzzles]);

  return (
    <>
      <MatrixRain />
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{ z: 1000, minHeight: "0.95" }}
        height="98vh"
      >
        <Box sx={{ z: 1000, width: "0.95", flexGrow: 1 }}>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            display="flex"
            sx={{ height: "100%" }}
          >
            {currentPuzzle ? (
              <PuzzleDisplay
                puzzle={currentPuzzle}
                solve={solvePuzzles(puzzles, setPuzzles)}
              />
            ) : (
              <img
                alt="GEOFIENDS"
                className="blink-image"
                src={process.env.PUBLIC_URL + "/hacker.png"}
                width={200}
              />
            )}
          </Grid>
        </Box>
        <PuzzleGraph puzzles={puzzles} setCurrentPuzzle={setCurrentPuzzleId} />
      </Stack>
    </>
  );
}
