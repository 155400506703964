import { Puzzle } from "./PuzzleDefinitions";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { Box, Grid } from "@mui/material";
import { Lock, Done, QuestionMark, MenuBook } from "@mui/icons-material";
import { Coordinate, GraphLines } from "./GraphLines";

function PuzzleIcon({
  puzzle,
  puzzles,
  puzzleCoordinates,
  setPuzzleCoordinates,
  setCurrentPuzzle,
}: {
  puzzle: Puzzle;
  puzzles: Puzzle[];
  puzzleCoordinates: Map<string, Coordinate>;
  setPuzzleCoordinates: Dispatch<SetStateAction<Map<string, Coordinate>>>;
  setCurrentPuzzle: Dispatch<SetStateAction<string | undefined>>;
}) {
  const isFinal = puzzle.id === "final";
  const square = isFinal
    ? { width: "50px", height: "45px" }
    : { width: "35px", height: "30px" };
  const unlocked = isPuzzleUnlocked(puzzle, puzzles);
  const solved = puzzle.solved;
  return (
    <Grid
      className={`${puzzle.id}-box`}
      item
      xs={3}
      zIndex={10}
      position="relative"
      key={`grid-${puzzle.id}-2`}
      sx={{
        border: unlocked ? "2px solid #34b434" : "2px solid red",
        minWidth: "25px",
        minHeight: "25px",
        background: "black",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        textAlign: "center",
        display: "flex",
        paddingY: "3px",
      }}
      onClick={(e) => {
        e.stopPropagation();
        if (unlocked) {
          setCurrentPuzzle(puzzle.id);
        } else {
          setCurrentPuzzle(undefined);
        }
      }}
      ref={(e: any) => {
        if (e) {
          const rect = e.getBoundingClientRect();
          const coord = {
            x: Math.round(rect.x + rect.width / 2),
            y: Math.round(rect.y + rect.height / 2),
          };
          if (
            puzzleCoordinates.get(puzzle.id)?.x !== coord.x ||
            puzzleCoordinates.get(puzzle.id)?.y !== coord.y
          ) {
            setPuzzleCoordinates(
              new Map(puzzleCoordinates.set(puzzle.id, coord))
            );
          }
        }
      }}
    >
      {!unlocked && <Lock sx={square} style={{ color: "red" }} />}
      {unlocked &&
        (solved ? (
          <Done sx={square} />
        ) : isFinal ? (
          <MenuBook sx={square} />
        ) : (
          <QuestionMark sx={square} />
        ))}
    </Grid>
  );
}

function isPuzzleUnlocked(puzzle: Puzzle, puzzles: Puzzle[]): boolean {
  let unlock = true;
  puzzle.dependants.forEach((id) => {
    if (!puzzles.find((p) => p.id === id)?.solved) {
      unlock = false;
    }
  });
  return unlock;
}

export function PuzzleGraph({
  puzzles,
  setCurrentPuzzle,
}: {
  puzzles: Array<Puzzle>;
  setCurrentPuzzle: Dispatch<SetStateAction<string | undefined>>;
}) {
  const [orderedGraph, setOrderedGraph] = useState<Array<Array<Puzzle>>>([]);
  const [puzzleCoordinates, setPuzzleCoordinates] = useState<
    Map<string, Coordinate>
  >(new Map());
  const [parentCoordinates, setParentCoordinates] = useState<
    Coordinate | undefined
  >();

  useMemo(() => {
    var currentNodes = puzzles
      .filter((p) => !p.dependants.length)
      .sort((x, y) => x.index - y.index);
    const newGraph = new Array<Array<Puzzle>>();
    while (currentNodes.length > 0) {
      newGraph.push(currentNodes);
      currentNodes = Array.from(
        new Set(
          puzzles.filter(
            // eslint-disable-next-line no-loop-func
            (node) =>
              !!currentNodes.filter((n) => node.dependants.includes(n.id))
                .length
          )
        )
      ).sort((x, y) => x.index - y.index);
    }
    setOrderedGraph(newGraph);
  }, [puzzles]);

  useEffect(() => {
    window.onresize = () => setParentCoordinates(undefined);
  }, []);

  if (!orderedGraph) {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "100%" }}
      >
        <Grid item xs={3}>
          <p text-align="center">???</p>
        </Grid>
      </Grid>
    );
  }

  const updateParentCoordinate = (e: Element) => {
    if (e) {
      const rect = e.getBoundingClientRect();
      const coord = { x: rect.x, y: rect.y };
      if (
        coord.x !== parentCoordinates?.x &&
        coord.y !== parentCoordinates?.y
      ) {
        setParentCoordinates(coord);
      }
    }
  };

  // console.log(orderedGraph);

  return (
    <Box
      onClick={() => setCurrentPuzzle(undefined)}
      ref={updateParentCoordinate}
      sx={{
        position: "relative",
        z: 0,
        background: "black",
        minHeight: "300px",
        maxHeight: "300px",
        border: "2px solid #34b434",
        width: "0.95",
      }}
    >
      <Box
        sx={{
          flexDirection: "row",
          alignItems: "center",
          display: "flex",
          justifyContent: "space-around",
          flexWrap: "nowrap",
          flexGrow: 1,
          minHeight: "100%",
        }}
      >
        {orderedGraph.map((column, index) => (
          <Box
            sx={{
              flexDirection: "column",
              flexGrow: 1,
              justifyContent: "space-around",
              height: "auto",
              display: "flex",
            }}
            key={`col-${index}`}
          >
            {column.map((puzzle) => (
              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                style={{ minHeight: "100%" }}
                p="15px"
                key={`grid-${puzzle.id}`}
              >
                <PuzzleIcon
                  puzzle={puzzle}
                  puzzles={puzzles}
                  puzzleCoordinates={puzzleCoordinates}
                  setPuzzleCoordinates={setPuzzleCoordinates}
                  setCurrentPuzzle={setCurrentPuzzle}
                />
              </Grid>
            ))}
          </Box>
        ))}
        {parentCoordinates && (
          <GraphLines
            puzzleCoordinates={puzzleCoordinates}
            puzzles={puzzles}
            parentCoordinate={parentCoordinates}
          />
        )}
      </Box>
    </Box>
  );
}
