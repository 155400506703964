import { Box } from "@mui/material";

export function LocationPuzzle() {
  document.body.style.overflow = "hidden";
  return (
    <>
      <div
        style={{
          backgroundImage: `url("${process.env.PUBLIC_URL}/no-globe.png")`,
          zIndex: -1,
          display: "block",
          position: "absolute",
        }}
      >
        <div
          className="blink-image"
          style={{
            backgroundImage: `url("${process.env.PUBLIC_URL}/globe.png")`,
            zIndex: 1,
            minHeight: "100vh",
            minWidth: "100vw",
            position: "relative",
          }}
        ></div>
      </div>
      <Box
        sx={{
          display: "flex",
          width: "100vw",
          height: "100vh",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            px: "3%",
            justifyContent: "space-evenly",
            width: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
          }}
        >
          <h2>[51.53454599455113, -0.2283099617755011]</h2>
          <h2>[51.57335994736686, -0.09417423563383208]</h2>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            px: "3%",
            justifyContent: "space-evenly",
            width: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
          }}
        >
          <h2>[51.49223508945871, -0.27506799035627566]</h2>
          <h2>[51.49223508945871, -0.27506799035627566]</h2>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            px: "3%",
            justifyContent: "space-evenly",
            width: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
          }}
        >
          <h2>[51.52549699108871, -0.08572912216106279]</h2>
          <h2>[51.52549699108871, -0.08572912216106279]</h2>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            px: "3%",
            justifyContent: "space-evenly",
            width: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
          }}
        >
          <h2>[51.57335994736686, -0.09417423563383208]</h2>
          <h2>[51.57335994736686, -0.09417423563383208]</h2>
        </Box>
      </Box>
    </>
  );
}
