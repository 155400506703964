import { Box } from "@mui/material";
import { useEffect } from "react";

const dot = [true, false];
const dash = [true, true, true, false];
const SPACE = [false, false];
const armadillo = [
  dot,
  dash,
  SPACE,
  dot,
  dash,
  dot,
  SPACE,
  dash,
  dash,
  SPACE,
  dot,
  dash,
  SPACE,
  dash,
  dot,
  dot,
  SPACE,
  dot,
  dot,
  SPACE,
  dot,
  dash,
  dot,
  dot,
  SPACE,
  dot,
  dash,
  dot,
  dot,
  SPACE,
  dash,
  dash,
  dash,
  SPACE,
  SPACE,
  SPACE,
  SPACE,
  SPACE,
].flat();

var pointer = 0;

export function Lights() {
  useEffect(() => {
    const job = setInterval(async () => {
      const on = armadillo[pointer];
      document.getElementById("light-label")!!.style.opacity = on
        ? "100%"
        : "10%";
      pointer = (pointer + 1) % armadillo.length;
      const url = "http://heg.cool/fuck-with-my-lights/server/color";
      await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(
          on ? { r: 20, g: 255, b: 20 } : { r: 0, g: 0, b: 0 }
        ),
      }).catch((e) => {
        console.log(e);
      });
    }, 333);

    return () => clearInterval(job);
  });

  return (
    <>
      <p
        style={{
          opacity: "5%",
          textAlign: "right",
          minWidth: "100%",
          display: "block",
          zIndex: 100,
        }}
      >
        password: LIGHTBULB .
      </p>
      <Box
        sx={{
          display: "flex",
          minWidth: "100vw",
          minHeight: "100vh",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: "2px solid #34b434",
            px: "3%",
          }}
        >
          <h1 id="light-label">ACTIVATING LIGHTS...</h1>
        </Box>
      </Box>
    </>
  );
}
