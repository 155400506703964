import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { Navigate } from "react-router-dom";
import { Box } from "@mui/material";

const [homeLatitude, homeLongitude, homeAltitude] = [
  51.519664, -0.042485, 11.007238388061523,
];

type Location = {
  x: number;
  y: number;
  z: number;
};

function getDistance(from: Location, to: Location) {
  const hypotenuse = Math.sqrt(
    Math.pow(from.x - to.x, 2) + Math.pow(from.y - to.y, 2)
  );
  // return Math.sqrt(Math.pow(from.z - to.z, 2) + Math.pow(hypotenuse, 2));
  return hypotenuse;
}

export function Warning() {
  const [doRedirect, setRedirect] = useState(false);
  const [distance, setDistance] = useState(9999999999);

  const updateLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const distance =
          getDistance(
            { x: homeLatitude, y: homeLongitude, z: homeAltitude },
            {
              x: position.coords.latitude,
              y: position.coords.longitude,
              z: position.coords.altitude,
            }
          ) * 111;
        if (distance < 1 && !!localStorage.getItem("geofiends-laptop")) {
          setRedirect(true);
        } else {
          setDistance(distance);
        }
      },
      (err) => console.error(err),
      { timeout: 5000 }
    );
  };

  useEffect(() => {
    updateLocation();
    const interval = setInterval(updateLocation, 5000);
    return () => clearInterval(interval);
  }, []);

  if (doRedirect) {
    return <Navigate to="/main-graph" />;
  }

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "100vh" }}
    >
      <img
        alt="GEOFIENDS"
        className="blink-image"
        src={process.env.PUBLIC_URL + "/hacker.png"}
        width={250}
      />
      <h3>
        Haha! You've been hacked by the <strong>GeoFIENDS!</strong>
      </h3>
      <p style={{ maxWidth: "66vw", textAlign: "center" }}>
        We have hacked you! haha! We have all your fucking documents and shit.
        We've got them and we're gonna POST them online. Or not! Maybe we'll
        hide them, if that's what you DON'T want! Ha! Fuck You! You Fucking
        infant! Next time don't leave your fancy gaming computer unlocked when
        you go away for christmas! Don't forget to check under the christmas
        tree for our other gifts!
      </p>
      <Box
        sx={{
          border: "3px solid red",
          color: "red",

          paddingX: "25px",
          flexDirection: "column",
          justifyContent: "center",
          justifyItems: "center",
          textJustify: "center",
          display: "flex",
        }}
      >
        <h3> GEOLOCATION LOCK: {distance > 1 ? "ENGAGED" : "WRONG DEVICE"} </h3>
        <p style={{ textAlign: "center" }}>distance: {distance}km</p>
      </Box>
      <p style={{ maxWidth: "66vw", textAlign: "center" }}>
        <small>
          P.S. Ricky if you need to login to our HACKING MAINFRAME™ just go back
          to the attack vector. Then the location lock should open.
        </small>
      </p>
    </Grid>
  );
}
